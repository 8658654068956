import React from 'react'
import { Link } from 'react-router-dom'

const RoomSectionCard = ({ cardImg, name, desc, children, slug }) => {
  return (
    <div className="col-xl-4 col-md-6">
      <Link to={`/room-details/${slug}`}>
        <div className="single-services mb-30">
          <div className="services-thumb">
              <img src={cardImg} alt="img" />
          </div>
          <div className="services-content">
            <div className="day-book">
              <ul>
                <li className="d-flex w-100">
                  <div className="text-center d-block w-100 text-uppercase">Check availability</div>
                </li>
              </ul>
            </div>
            <h4>{name}</h4>
            <p>{desc}</p>
            <div className="icon">
              <ul>
                {children}
              </ul>
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}

export default RoomSectionCard
