import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import Navbar from '../../component/Navbar/Main'
import Footer from '../../component/Footer/Main'
import BGImg from "../../assets/img/slider/210708662.jpg";
import BGImg1 from "../../assets/img/slider/39637078.jpg";
import BGImg2 from "../../assets/img/slider/361633687.jpg";
import BGImg3 from "../../assets/img/slider/kitchen.jpg";
import BGImg4 from "../../assets/img/slider/bathroom.jpg";
import BGTest from "../../assets/img/bg/testimonial-bg.png";
import BGVideo from "../../assets/img/bg/video-bg.png";
import Slider from "react-slick";
import FsLightbox from "fslightbox-react";
import About from "../../assets/img/bg/an-img-02.png";
import About1 from "../../assets/img/features/about_img_02.png";
import About2 from "../../assets/img/features/about_img_03.png";
import About3 from "../../assets/img/features/signature.png";
import Hotel from "../../assets/img/bg/an-img-01.png";
import Hotel1 from "../../assets/img/icon/fe-icon01.png";
import Hotel2 from "../../assets/img/icon/fe-icon04.png";
import Hotel3 from "../../assets/img/icon/fe-icon05.png";
import Hotel4 from "../../assets/img/icon/fe-icon06.png";
import Hotel5 from "../../assets/img/icon/fe-icon07.png";
import Hotel6 from "../../assets/img/icon/fe-icon08.png";

import Resort from "../../assets/img/bg/an-img-02.png";
import Resort1 from "../../assets/img/features/feature.png";
import pricing1 from "../../assets/img/bg/an-img-02.png";
import avatar1 from "../../assets/img/testimonial/avatar1.png";
import avatar2 from "../../assets/img/testimonial/avatar2.png";
import avatar4 from "../../assets/img/testimonial/avatar4.png";
import avatar3 from "../../assets/img/testimonial/avatar3.png";
import Aicon from "../../assets/img/testimonial/qt-icon.png";
import Aicon5 from "../../assets/img/testimonial/review-icon.png";
import Aicon4 from "../../assets/img/testimonial/review-icon-4.png";
import Booking from "../../assets/img/bg/booking-img.png";
import Play from "../../assets/img/bg/play-button.png";
import Blog from "../../assets/img/bg/an-img-06.png";
import Blog1 from "../../assets/img/blog/inner_b1.jpg";
import Blog2 from "../../assets/img/blog/inner_b2.jpg";
import Blog3 from "../../assets/img/blog/inner_b3.jpg";
import Brand1 from "../../assets/img/brand/b-logo1.png";
import Brand2 from "../../assets/img/brand/b-logo2.png";
import Brand3 from "../../assets/img/brand/b-logo3.png";
import Brand4 from "../../assets/img/brand/b-logo4.png";
import Brand5 from "../../assets/img/brand/b-logo5.png";

import RoomsSection from "../RoomsSection";
import DirectionsSection from "../DirectionsSection";

const Main = () => {
  const [yShow, setyShow] = useState();
  const [hShow, sethShow] = useState();
  const [animation, setanimation] = useState();

  const [toggler, setToggler] = useState(false);
  const [activeImage, setActiveImage] = useState(1);
  const mapRef = useRef(null)

  const [images, setImages] = useState([
    "assets/img/gallery/room-img01.png",
    "assets/img/gallery/room-img02.png",
    "assets/img/gallery/room-img03.png",
    "assets/img/gallery/room-img04.png",
    "assets/img/gallery/room-img05.png",
    "assets/img/gallery/room-img06.png",
  ]);

  const home = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 900,
    autoplay: true,
    slidesToShow: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const testimonial = {
    initialIndex: 2,
    dots: true,
    arrows: false,
    infinite: true,
    speed: 900,
    autoplay: true,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const brand = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 900,
    autoplay: false, //true,
    slidesToShow: 4,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
    <Navbar />
      <main>
        <section id="home" className="slider-area fix p-relative">
          <Slider className="slider-active" {...home}>
            <div className="col-xl-2">
              <div
                className="single-slider slider-bg d-flex align-items-center"
                style={{
                  background: `url(${BGImg}) no-repeat top center`,
                  backgroundSize: "cover",
                }}
              >
                <div className="container">
                  <div className="row justify-content-center align-items-center">
                    <div className="col-lg-7 col-md-7">
                      <div className="slider-content s-slider-content mt-80 text-center">
                        <h2
                          className={`${
                            animation && "animation" && "fadeInUp"
                          } `}
                          data-animation="fadeInUp"
                          data-delay=".4s"
                          style={{ animationDelay: "0.4s" }}
                        >
                          ENJOY BUDAPEST&thinsp;!
                        </h2>
                        <p data-animation="fadeInUp" data-delay=".6s">
                        Wellcome in one of the most beautiful cities in the world! Thousand years of history, beautiful architecture, endless programs for every age. Enjoy the quality and design of our hostel!

                        </p>

                        <div className="slider-btn mt-30 mb-105">
                          {/* <Link
                            to="#"
                            onClick={() => sethShow(true)}
                            className="video-i popup-video"
                            data-animation="fadeInUp"
                            data-delay=".8s"
                            style={{ animation_delay: " 0.8s" }}
                            tabIndex="0"
                          >
                            <i className="fas fa-play"></i> Intro Video
                          </Link> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-2">
              <div
                className="single-slider slider-bg d-flex align-items-center"
                style={{
                  background: `url(${BGImg1}) no-repeat top center`,
                  backgroundSize: "cover",
                }}         
              >
                <div className="container">
                  <div className="row justify-content-center align-items-center">
                    <div className="col-lg-7 col-md-7">
                      <div className="slider-content s-slider-content mt-80 text-center">
                        <h2
                          data-animation="fadeInUp"
                          data-delay=".4s"
                          style={{ animationDelay: "0.4s" }}
                        >
                          ENJOY BUDAPEST&thinsp;!
                        </h2>
                        <p data-animation="fadeInUp" data-delay=".6s">
                        Wellcome in one of the most beautiful cities in the world! Thousand years of history, beautiful architecture, endless programs for every age. Enjoy the quality and design of our hostel!
                        </p>

                        <div className="slider-btn mt-30 mb-105">
                          {/* <Link
                            to="#"
                            onClick={() => sethShow(true)}
                            className="video-i popup-video"
                            data-animation="fadeInUp"
                            data-delay=".8s"
                            style={{ animation_delay: " 0.8s" }}
                            tabIndex="0"
                          >
                            <i className="fas fa-play"></i> Intro Video
                          </Link> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-2">
              <div
                className="single-slider slider-bg d-flex align-items-center"
                style={{
                  background: `url(${BGImg2}) no-repeat top center`,
                  backgroundSize: "cover",
                }}         
              >
                <div className="container">
                  <div className="row justify-content-center align-items-center">
                    <div className="col-lg-7 col-md-7">
                      <div className="slider-content s-slider-content mt-80 text-center">
                        <h2
                          data-animation="fadeInUp"
                          data-delay=".4s"
                          style={{ animationDelay: "0.4s" }}
                        >
                          ENJOY BUDAPEST&thinsp;!
                        </h2>
                        <p data-animation="fadeInUp" data-delay=".6s">
                        Wellcome in one of the most beautiful cities in the world! Thousand years of history, beautiful architecture, endless programs for every age. Enjoy the quality and design of our hostel!
                        </p>

                        <div className="slider-btn mt-30 mb-105">
                          {/* <Link
                            to="#"
                            onClick={() => sethShow(true)}
                            className="video-i popup-video"
                            data-animation="fadeInUp"
                            data-delay=".8s"
                            style={{ animation_delay: " 0.8s" }}
                            tabIndex="0"
                          >
                            <i className="fas fa-play"></i> Intro Video
                          </Link> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-2">
              <div
                className="single-slider slider-bg d-flex align-items-center"
                style={{
                  background: `url(${BGImg3}) no-repeat top center`,
                  backgroundSize: "cover",
                }}         
              >
                <div className="container">
                  <div className="row justify-content-center align-items-center">
                    <div className="col-lg-7 col-md-7">
                      <div className="slider-content s-slider-content mt-80 text-center">
                        <h2
                          data-animation="fadeInUp"
                          data-delay=".4s"
                          style={{ animationDelay: "0.4s", color: 'white' }}
                        >
                          ENJOY BUDAPEST&thinsp;!
                        </h2>
                        <p data-animation="fadeInUp" data-delay=".6s" style={{ color: 'white'}}>
                        Wellcome in one of the most beautiful cities in the world! Thousand years of history, beautiful architecture, endless programs for every age. Enjoy the quality and design of our hostel!
                        </p>

                        <div className="slider-btn mt-30 mb-105">
                          {/* <Link
                            to="#"
                            onClick={() => sethShow(true)}
                            className="video-i popup-video"
                            data-animation="fadeInUp"
                            data-delay=".8s"
                            style={{ animation_delay: " 0.8s" }}
                            tabIndex="0"
                          >
                            <i className="fas fa-play"></i> Intro Video
                          </Link> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-2">
              <div
                className="single-slider slider-bg d-flex align-items-center"
                style={{
                  background: `url(${BGImg4}) no-repeat top center`,
                  backgroundSize: "cover",
                }}         
              >
                <div className="container">
                  <div className="row justify-content-center align-items-center">
                    <div className="col-lg-7 col-md-7">
                      <div className="slider-content s-slider-content mt-80 text-center">
                        <h2
                          data-animation="fadeInUp"
                          data-delay=".4s"
                          style={{ animationDelay: "0.4s" }}
                        >
                          ENJOY BUDAPEST&thinsp;!
                        </h2>
                        <p data-animation="fadeInUp" data-delay=".6s">
                        Wellcome in one of the most beautiful cities in the world! Thousand years of history, beautiful architecture, endless programs for every age. Enjoy the quality and design of our hostel!
                        </p>

                        <div className="slider-btn mt-30 mb-105">
                          {/* <Link
                            to="#"
                            onClick={() => sethShow(true)}
                            className="video-i popup-video"
                            data-animation="fadeInUp"
                            data-delay=".8s"
                            style={{ animation_delay: " 0.8s" }}
                            tabIndex="0"
                          >
                            <i className="fas fa-play"></i> Intro Video
                          </Link> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </section>
        {/* <div id="booking" className="booking-area p-relative">
          <div className="container">
            <form action="#" className="contact-form">
              <div className="row align-items-center">
                <div className="col-lg-12">
                  <ul>
                    <li>
                      <div className="contact-field p-relative c-name">
                        <label>
                          <i className="fal fa-badge-check"></i> Check In Date
                        </label>
                        <input type="date" id="chackin" name="date" />
                      </div>
                    </li>
                    <li>
                      <div className="contact-field p-relative c-name">
                        <label>
                          <i className="fal fa-times-octagon"></i> Check Out
                          Date
                        </label>
                        <input type="date" id="chackout" name="date" />
                      </div>
                    </li>
                    <li>
                      <div className="contact-field p-relative c-name">
                        <label>
                          <i className="fal fa-users"></i> Adults
                        </label>
                        <select name="adults" id="adu">
                          <option value="sports-massage">Adults</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                        </select>
                      </div>
                    </li>
                    <li>
                      <div className="contact-field p-relative c-name">
                        <label>
                          <i className="fal fa-baby"></i> Child
                        </label>
                        <select name="child" id="cld">
                          <option value="sports-massage">Child</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                        </select>
                      </div>
                    </li>
                    <li>
                      <div className="contact-field p-relative c-name">
                        <label>
                          <i className="fal fa-concierge-bell"></i> Room
                        </label>
                        <select name="room" id="rm">
                          <option value="sports-massage">Room</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                        </select>
                      </div>
                    </li>
                    <li>
                      <div className="slider-btn">
                        <label>
                          <i className="fal fa-calendar-alt"></i>
                        </label>
                        <button
                          className="btn ss-btn"
                          data-animation="fadeInRight"
                          data-delay=".8s"
                        >
                          Check Availability
                        </button>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </form>
          </div>
        </div> */}
        <RoomsSection id="rooms" />

        <DirectionsSection id="map" /> 
       
        <section
          id="amenities"
          className="pt-120 pb-90 p-relative"
          style={{ background: "#f7f5f1" }}
        >
          <div className="animations-01">
            <img src={Hotel} alt="an-img-01" />
          </div>
          <div className="container">
            <div className="row align-items-center">

              <div className="col-lg-12">
                <div className="section-title center-align mb-50 text-center">
                  <h5>Explore</h5>
                  <h2>Amenities</h2>
                  <p>
                    We offer breakfast, laundry and ironing if needed, and electric scooters for easy sightseeing.
                  </p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className="services-08-item mb-30">
                  <div className="services-icon2">
                    <img src={Hotel1} alt="img" />
                  </div>
                  <div className="services-08-thumb">
                    <img src={Hotel1} alt="img" />
                  </div>
                  <div className="services-08-content">
                    <h3>
                      <Link to="/service-details">Cosy Beds</Link>
                    </h3>
                    <p>
                      Comfy double beds with natural bedcover, bedlinen, blanket, pillow and towels.
                      <br /><br />
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className="services-08-item mb-30">
                  <div className="services-icon2">
                    <img src={Hotel2} alt="img" />
                  </div>
                  <div className="services-08-thumb">
                    <img src={Hotel2} alt="img" />
                  </div>
                  <div className="services-08-content">
                    <h3>
                      Excellent Location
                    </h3>
                    <p>
                      The hostel is located in a fin-de-siècle building, just 700 m from the State Opera and a 10-minute walk from the St. Stephen’s Cathedral.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className="services-08-item mb-30">
                  <div className="services-icon2">
                    <img src={Hotel5} alt="img" />
                  </div>
                  <div className="services-08-thumb">
                    <img src={Hotel5} alt="img" />
                  </div>
                  <div className="services-08-content">
                    <h3>
                      Breakfast
                    </h3>
                    <p>
                      Breakfast available in the bar downstairs from 9AM – 3PM.
                      <br /><br />
                    </p>
                  </div>
                </div>
              </div>
{/* 
              <div className="col-lg-4 col-md-6">
                <div className="services-08-item mb-30">
                  <div className="services-icon2">
                    <img src={Hotel3} alt="img" />
                  </div>
                  <div className="services-08-thumb">
                    <img src={Hotel3} alt="img" />
                  </div>
                  <div className="services-08-content">
                    <h3>
                      <Link to="/service-details">Best Accommodation</Link>
                    </h3>
                    <p>
                      Nullam molestie lacus sit amet velit fermentum feugiat.
                      Mauris auctor eget nunc sit amet.
                    </p>
                    <Link to="/service-details">
                      Read More <i className="fal fa-long-arrow-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="services-08-item mb-30">
                  <div className="services-icon2">
                    <img src={Hotel4} alt="img" />
                  </div>
                  <div className="services-08-thumb">
                    <img src={Hotel4} alt="img" />
                  </div>
                  <div className="services-08-content">
                    <h3>
                      <Link to="/service-details"> Wellness & Spa</Link>
                    </h3>
                    <p>
                      Nullam molestie lacus sit amet velit fermentum feugiat.
                      Mauris auctor eget nunc sit amet.
                    </p>
                    <Link to="/service-details">
                      Read More <i className="fal fa-long-arrow-right"></i>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className="services-08-item mb-30">
                  <div className="services-icon2">
                    <img src={Hotel6} alt="img" />
                  </div>
                  <div className="services-08-thumb">
                    <img src={Hotel6} alt="img" />
                  </div>
                  <div className="services-08-content">
                    <h3>
                      <Link to="/service-details">Special Offers</Link>
                    </h3>
                    <p>
                      Nullam molestie lacus sit amet velit fermentum feugiat.
                      Mauris auctor eget nunc sit amet.
                    </p>
                    <Link to="/service-details">
                      Read More <i className="fal fa-long-arrow-right"></i>
                    </Link>
                  </div>
                </div>
              </div> */}

            </div>
          </div>
        </section>

        <section id="reviews"
          className="testimonial-area pt-120 pb-90 p-relative fix"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title center-align mb-50 text-center">
                  <h5>Reviews</h5>
                  <h2>What Our Guests Says</h2>
                  <p>
                    
                  </p>
                </div>
              </div>
              <div className="col-lg-12">
                <Slider className="testimonial-active" {...testimonial}>
                  <div className="single-testimonial">
                    <div className="testi-author">
                      <img src={avatar1} alt="img" />
                      <div className="ta-info">
                        <h6>Sarah</h6>
                        <span>Germany</span>
                      </div>
                    </div>
                    <div className="review-icon">
                      <img src={Aicon4} alt="img" />
                    </div>
                    <p>
                      “the location was amazing - really in the centre of everything!”.
                    </p>

                    <div className="qt-img">
                      <img src={Aicon} alt="img" />
                    </div>
                  </div>
                  <div className="single-testimonial">
                    <div className="testi-author">
                      <img src={avatar3} alt="img" />
                      <div className="ta-info">
                        <h6>Daniel</h6>
                        <span>Mexico</span>
                      </div>
                    </div>
                    <div className="review-icon">
                      <img src={Aicon5} alt="img" />
                    </div>
                    <p>
                      “Comfy bed, clean bedroom bathroom and common areas, check in was super easy, location is great. Best value for money in the liveliest quarter”
                    </p>

                    <div className="qt-img">
                      <img src={Aicon} alt="img" />
                    </div>
                  </div>
                  <div className="single-testimonial">
                    <div className="testi-author">
                      <img src={avatar4} alt="img" />
                      <div className="ta-info">
                        <h6>Catalina</h6>
                        <span>Germany</span>
                      </div>
                    </div>
                    <div className="review-icon">
                      <img src={Aicon4} alt="img" />
                    </div>
                    <p>
                      “Close to everything. The bathroom is outside the room as it is shared but is really clean and big. They installed windows for the noise outside so it was good. Spacious room. No breakfast included, but you can prepare in the kitchen. It has a big fridge.”
                    </p>

                    <div className="qt-img">
                      <img src={Aicon} alt="img" />
                    </div>
                  </div>

                  <div className="single-testimonial">
                    <div className="testi-author">
                      <img src={avatar2} alt="img" />
                      <div className="ta-info">
                        <h6>Hall</h6>
                        <span>UK</span>
                      </div>
                    </div>
                    <div className="review-icon">
                      <img src={Aicon5} alt="img" />
                    </div>
                    <p>
                      “Fantastic 4 days and would definitely stay again. Host was brilliant, helped us with directions, site locations and good”.
                    </p>

                    <div className="qt-img">
                      <img src={Aicon} alt="img" />
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </section>
      </main>

      <FsLightbox toggler={toggler} sources={images} slide={activeImage} />

      {yShow && (
        <>
          <div className="mfp-bg mfp-ready"></div>
          <div
            className="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-ready"
            tabIndex="-1"
            style={{ overflow: "hidden" }}
          >
            <div className="mfp-container mfp-s-ready mfp-iframe-holder">
              <div className="mfp-content">
                <div className="mfp-iframe-scaler">
                  <button
                    title="Close (Esc)"
                    type="button"
                    className="mfp-close"
                    onClick={() => setyShow(false)}
                  >
                    ×
                  </button>
                  <iframe
                    className="mfp-iframe"
                    src="//www.youtube.com/embed/gyGsPlt06bo?autoplay=1"
                    frameborder="0"
                    allowFullScreen=""
                  ></iframe>
                </div>
              </div>
              <div className="mfp-preloader">Loading...</div>
            </div>
          </div>
        </>
      )}
      {hShow && (
        <>
          <div className="mfp-bg mfp-ready"></div>
          <div
            className="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-ready"
            tabIndex="-1"
            style={{ overflow: "hidden" }}
          >
            <div className="mfp-container mfp-s-ready mfp-iframe-holder">
              <div className="mfp-content">
                <div className="mfp-iframe-scaler">
                  <button
                    title="Close (Esc)"
                    type="button"
                    className="mfp-close"
                    onClick={() => sethShow(false)}
                  >
                    ×
                  </button>
                  <iframe
                    className="mfp-iframe"
                    src="//www.youtube.com/embed/gyGsPlt06bo?autoplay=1"
                    frameborder="0"
                    allowFullScreen=""
                  ></iframe>
                </div>
              </div>
              <div className="mfp-preloader">Loading...</div>
            </div>
          </div>
        </>
      )}
    <Footer/>
    </>
  );
};

export default Main;
