import React , { useState } from 'react'
import { Link } from 'react-router-dom'

import Slider from "react-slick"

import service1 from "../../assets/img/gallery/361633687.jpg"
import service2 from "../../assets/img/gallery/39637078.jpg"
import service3 from "../../assets/img/gallery/210708661.jpg"
import icon1 from "../../assets/img/icon/sve-icon1.png"
import icon2 from "../../assets/img/icon/sve-icon2.png"
import icon3 from "../../assets/img/icon/sve-icon3.png"
import icon4 from "../../assets/img/icon/sve-icon4.png"
import icon5 from "../../assets/img/icon/sve-icon5.png"
import icon6 from "../../assets/img/icon/sve-icon6.png"
import RoomSectionCard from '../RoomSectionCard'

const RoomsSection = (props) => {
  const service = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 900,
    autoplay: true,
    slidesToShow: 3,
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
}


  return (
    <section {...props} className="services-area pt-113 pb-150" style={{ background: '#D0D0AF66' }}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-12">
            <div className="section-title center-align mb-50 text-center">
              <h5>The pleasure of luxury</h5>
              <h2>Rooms</h2>
              <p>All rooms are individually decorated, bright and come with wooden floors, high-speed WIFI, comfí beds with natural bed sheets. The shared-use bathrooms are located next the rooms on the corridor.</p>
            </div>
          </div>
        </div>
        <Slider className="row services-active" {...service}>
          {RoomSectionCard({
            cardImg: "/queen-studio/361633687-700x466.jpg",
            slug: 'queen-studio',
            name: "Queen Studio",
            desc: "The fully equipped kitchen features a refrigerator, a dishwasher, kitchenware and an oven.",
            children: [
              <li className="fal fa-bed" alt="img"></li>,
              <li className="fal fa-wifi" alt="img"></li>, 
              <li className="fal fa-tv" alt="img"></li>, 
              <li className="fal fa-utensils" alt="img"></li>, 
              <li className="fal fa-mug-hot" alt="img"></li>,
              <li className="fal fa-shower" alt="img"></li>, 
              <li className="fal fa-dryer-alt" alt="img"></li>, 
              <li className="fal fa-parking-circle" alt="img"></li>
            ]
          })}

          {RoomSectionCard({
            cardImg: '/superior-king-room/210708662-700x466.jpg',
            slug: "superior-king-room",
            name: "Superior King Room",
            desc: "The double room features air conditioning, a washing machine, as well as a shared bathroom boasting a bath and a shower.",
            children: [
              <li className="fal fa-bed" alt="img"></li>, 
              <li className="fal fa-wifi" alt="img"></li>, 
              <li className="fal fa-air-conditioner" alt="img"></li>, 
              <li className="fal fa-bath" alt="img"></li>, 
              <li className="fal fa-dryer-alt" alt="img"></li>,
              <li className="fal fa-parking-circle" alt="img"></li>
            ]
          })}

          {RoomSectionCard({
            cardImg: "/large-double-room/39637078-700x466.jpg",
            slug: "large-double-room",
            name: "Large Double Room",
            desc: "The double room offers a washing machine, a tea and coffee maker, as well as a shared bathroom boasting a bath and a shower.",
            children: [
              <li className="fal fa-bed" alt="img"></li>,
              <li className="fal fa-wifi" alt="img"></li>,
              <li className="fal fa-mug-hot" alt="img"></li>,
              <li className="fal fa-bath" alt="img"></li>,
              <li className="fal fa-dryer-alt" alt="img"></li>,
              <li className="fal fa-parking-circle" alt="img"></li>
            ]
          })}

          {RoomSectionCard({
            cardImg: '/small-double-room/120600402-700x466.jpg',
            slug: "small-double-room",
            name: "Small Double Double",
            desc: "The double room provides air conditioning, a washing machine, as well as a shared bathroom featuring a bath and a shower.",
            children: [
              <li className="fal fa-bed" alt="img"></li>, 
              <li className="fal fa-wifi" alt="img"></li>, 
              <li className="fal fa-air-conditioner" alt="img"></li>,
              <li className="fal fa-bath" alt="img"></li>,
              <li className="fal fa-dryer-alt" alt="img"></li>,
              <li className="fal fa-parking-circle" alt="img"></li>,
            ]
          })}          

          {RoomSectionCard({
            cardImg: "/superior-apartment/363048240-700x466.jpg",
            slug: "superior-apartment",
            name: "Superior Apartment",
            desc: "This spacious apartment is comprised of 1 living room, 4 separate bedrooms and 2 bathrooms with a bath and a shower.",
            children: [
              <li className="fal fa-bed" alt="img"></li>,
              <li className="fal fa-wifi" alt="img"></li>, 
              <li className="fal fa-tv" alt="img"></li>, 
              <li className="fal fa-air-conditioner" alt="img"></li>, 
              <li className="fal fa-mug-hot" alt="img"></li>,
              <li className="fal fa-bath" alt="img"></li>, 
              <li className="fal fa-dryer-alt" alt="img"></li>, 
              <li className="fal fa-parking-circle" alt="img"></li>,
            ]
          })}          

        </Slider>
      </div>
    </section>
  )
}

export default RoomsSection
