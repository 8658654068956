import React from 'react';
import { Link, Navigate } from "react-router-dom";


const ErrorPage = () =>{
    return (
      <div className="error-page">
        <div className="logo">
          <Link to="/">
            <img src="/assets/acacia-logo-negative.svg" alt="logo" />
          </Link>
        </div>
        <h2>Something went wrong!</h2>
        <hr />
        <p>
          <Link to="/">Go back to the home page</Link>
          {/* <Navigate to="/" /> */}
        </p>
      </div>
    );
  }

export default ErrorPage;