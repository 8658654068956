const DirectionsSection = ({ ...rest }) => {
  return (
    <section {...rest} className="about-area about-p pt-120 pb-120 p-relative fix">
    <div className="container">
      <div className="direction-container row justify-content-center align-items-center">
        <div className="col-lg-6 col-md-12 col-sm-12">
          <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2695.485385910363!2d19.065959799999998!3d47.4999377!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4741dc689a0cefb9%3A0x627728112e9581d7!2sAk%C3%A1cfa+u.+43%2C+Budapest%2C+1072!5e0!3m2!1shu!2shu!4v1440245436033" width="100%" height="450" frameborder="0" style={{ border: 0 }} allowfullscreen />
        </div>
        <div className="col-lg-6 col-md-12 col-sm-12 pt-40 pb-40">
          <div
            className="about-content s-about-content  wow fadeInRight  animated pl-30"
            data-animation="fadeInRight"
            data-delay=".4s"
          >
            <div className="about-title second-title pb-25">
              <h5>How to Get There</h5>
              <h2>Directions</h2>
            </div>
            <p>
              Acacia Hostel is located in a vibrant area Budapest and offers rooms with free WiFi and a kitchen that can be used by all guests. The hostel is located in a fin-de-siècle building, just 700 m from the State Opera and a 10-minute walk from the St. Stephen’s Cathedral.
            </p>
            <div className="mt-30">
              <div className="row justify-content-center align-items-center">
                <h5 className="col-md-12 green mb-30 font-black text-lg">
            1072 Budapest, Akácfa utca 43.
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default DirectionsSection
