import { BrowserRouter as Router } from "react-router-dom"
import { Route, Routes } from 'react-router-dom'
import Home from './component/Home/Main'
import RoomDetails from './component/Rooms/RoomDetails/Main'
import ErrorPage from "./component/Pages/ErrorPage/Main"
import './assets/css/bootstrap.min.css'
import './assets/css/animate.min.css'
import './assets/css/magnific-popup.css'
import './assets/fontawesome/css/all.min.css'
import './assets/css/dripicons.css'
import './assets/css/slick.css'
import './assets/css/meanmenu.css'
import './assets/css/default.css'
import './assets/css/style.css'
import './assets/css/responsive.css'
import './assets/css/error.css'


export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/room-details/:roomId" element={<RoomDetails />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </Router>
  );
}

